.cookies-background{
	position: absolute;
	top:0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #000;
	opacity: 0.9;
	z-index: 10;
	height: 280vh;
	width: 100%;
	overflow:hidden;
	
}
.cookies-background-disappear{
	position: absolute;
	top:0;
	left: 0;
	background-color: #000;
	opacity: 0;
	z-index: 10;
	height:100%;
	width: 100%;
	animation-name: hide-animation;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
}

.follow-shark{
	position: absolute;
	top: 50%;
	transform: translate(50%, -50%);
	padding: 10px;
	width: 60vw;
	height: 30vh;
	z-index: 11;
	color: #0FF;
	text-align: center;
	animation-name: popup;
    animation-duration: 0.2s;
    animation-timing-function: ease-in-out;
}
.follow-shark h1{
	text-align: left;
}
.follow-shark h1 span{
	letter-spacing: 4px;
}
.cookies-popup{
	position: absolute;
	top: 50%;
	transform: translate(30%, -50%);
	padding: 10px;
	width: 60vw;
	height: 30vh;
	background-image: url(/public/lc_ico/cookies.svg);
	background-repeat: no-repeat;
	background-position-x: 0%;
	background-position-y: 100%;
	background-size: 13%;
	background-color:  hsla(0, 0%, 100%, .6);
	z-index: 11;
	text-align: center;
	animation-name: popup;
    animation-duration: 3s;
    animation-timing-function: ease-in-out;
}

.cookies-popup-disappear{
	position: absolute;
	display: none;
	top: 50%;
	transform: translate(30%, -50%);
	padding: 10px;
	width: 60vw;
	height: 30vh;
	background-color:  hsla(0, 0%, 100%, .6);
	z-index: -1;
	opacity: 0;
	text-align: center;
	animation-name: hide-animation;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
}

.cookies-popup p, .cookies-popup-disappear p {
	color: #000;
	font-size: 24px;
}


.social-popup{
	position: fixed;
	top: 50%;
	right: 0px;
	width: 250px;
	height: 250px;
	padding: 20px;
	background-color:  hsla(0, 0%, 100%, .6);
	z-index: 11;
	opacity: 0.7;
	text-align: left;
	animation-name: popup;
    animation-duration: 3s;
    animation-timing-function: ease-in-out;
}
.social-popup-close-button{
	position: absolute;
	cursor: pointer;
	top: 5px;
	left: 5px;
	font-size: 30px;
	font-weight: 500;
	text-shadow: #FFF 2px 2px 2px;
}
.social-popup a img:not(.google-ico img){
	margin-right: 10px;
	width: 40px;
	height: 40px;
	filter: brightness(1) invert(1);
}
.google-ico img{
	margin-right: 10px;
	width: 40px;
	height: 40px;
	filter: brightness(0) invert(1);
}
.social-logotype{
	width: 180px;
	margin: 10px 20px 30px 15px;
	filter: brightness(0) invert(1);
}
.social-popup p{
	font-size: 11px;
	color: #FFF;
}
.social-popup a{
	color: #FFF;
	font-size: 11px;
	text-decoration: none;
}
.social-popup a:hover{
	text-decoration: none;
	transform: scale(2.0);
	color: #FFF;
}

.social-popup a:active{
	text-decoration: none;
	transform: scale(0.2);
	color: #FFF;
}

@keyframes popup {
  from {
  	opacity: 0;
  	display: none;
  }
  to {
  	opacity: 0.7;
  	display: initial;
  }
}

@keyframes hide-animation {
  from {
  	opacity: 1;
	  z-index: 10;
  }
  to {
  	display: none;
	z-index: -1;
  	opacity: 0;
  }
}
.lc-button {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	margin: 20px auto;
	width: 30vw;
	height: 80px;
	background: white;
	font-family: 'Montserrat', sans-serif;
	font-size: 20px;
	font-weight: lighter;
	letter-spacing: 2px;
	transition: 1s box-shadow;
  }
  
  .lc-button:hover {
	cursor: pointer;
	box-shadow: 0 3px 7px 0px rgba(0,0,0,.1);
  }
  
  .lc-button:hover::before, .lc-button:hover::after {
	display: block;
	content: '';
	position: absolute;
	width: 30vw;
	height: 80px;
	background: #0FF;
	z-index: -1;
	animation: 5s clockwise infinite;
  }
  
  .lc-button:hover:after {
	background: #03BCFC;
	animation: 5s counterclockwise infinite;
  }
  
  @keyframes clockwise {
	0% {
	  top: -5px;
	  left: 0;
	}
	12% {
	  top: -4px;
	  left: 4px;
	}
	25% {
	  top: 0;
	  left: 5px;    
	}
	37% {
	  top: 4x;
	  left: 4px;
	}
	50% {
	  top: 5px;
	  left: 0;    
	}
	62% {
	  top: 4px;
	  left: -4px;
	}
	75% {
	  top: 0;
	  left: 5px;
	}
	87% {
	  top: -4px;
	  left: -4px;
	}
	100% {
	  top: -5px;
	  left: 0;    
	}
  }
  
  @keyframes counterclockwise {
	0% {
	  top: -5px;
	  right: 0;
	}
	12% {
	  top: -4px;
	  right: 4px;
	}
	25% {
	  top: 0;
	  right: 5px;    
	}
	37% {
	  top: 4px;
	  right: 4px;
	}
	50% {
	  top: 5px;
	  right: 0;    
	}
	62% {
	  top: 4px;
	  right: -4px;
	}
	75% {
	  top: 0;
	  right: -5px;
	}
	87% {
	  top: -4px;
	  right: -4px;
	}
	100% {
	  top: -5px;
	  right: 0;    
	}
  }